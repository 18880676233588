/**
 * 百度追踪代码 YC官网用
 */
var _hmt = _hmt || []
;(function () {
  var hm = document.createElement('script')
  hm.src = 'https://hm.baidu.com/hm.js?5fafeca31ea73a7c2ecef3c316c7864a'
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(hm, s)
})()
