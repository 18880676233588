<template>
    <el-dialog v-model="isDialogShow" width="1028px" :close-on-click-modal="false" :destroy-on-close="true"
        @close="$emit('close')" center custom-class="start-login-dialog">
        <div class="left-container">
            <img src="@/assets/images/start/logo.svg" alt="">
            <img src="@/assets/images/start/left-bg.png" alt="">
        </div>
        <div class="right-container">
            <div class="close" @click="isDialogShow = false">
                <img src="@/assets/images/start/close.svg" alt="">
            </div>
            <div class="login-container" v-if="currentType === 'login'">
                <!-- 账号密码/手机验证码登录区域 -->
                <div class="pc-login" v-if="loginType === 'pcLogin'">
                    <img src="@/assets/images/start/wx-login.svg" alt="" class="type"
                        @click="loginType = 'wxLogin'; wxImg()">
                    <div class="title">欢迎使用YinoCloud易诺云</div>
                    <el-tabs v-model="loginActiveName">
                        <el-tab-pane label="手机验证码登录" name="phoneLogin">
                            <!-- 手机验证码表单登录区 -->
                            <el-form ref="phoneLoginFormRef" :model="phoneLoginForm" :rules="phoneLoginFormRules"
                                label-width="0px">
                                <el-form-item prop="phone">
                                    <el-input placeholder="请输入手机号" v-model="phoneLoginForm.phone" clearable
                                        :validate-event="false">
                                        <template #prefix>
                                            <img src="@/assets/images/start/phone.svg" alt="">
                                        </template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item prop="phoneCode">
                                    <el-row :gutter="20">
                                        <el-col :span="16" style="padding-right: 0;">
                                            <el-input v-model="phoneLoginForm.phoneCode" placeholder="请输入验证码" clearable
                                                :validate-event="false">
                                                <template #prefix>
                                                    <img src="@/assets/images/start/code.svg" alt="">
                                                </template>
                                            </el-input>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-button :disabled="!checkPhone(phoneLoginForm.phone)" @click="getCode"
                                                class="code-button">
                                                {{ countDownTime > 0 ? `已发送${countDownTime}s` : '获取验证码' }}
                                            </el-button>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <el-row align="middle" style="margin-bottom: 24px;">
                                    <el-checkbox v-model="agreeYino" label="" class="agree-checkbox"></el-checkbox>
                                    <div class="agree-box">
                                        <span class="Text">登录即表示同意</span>
                                        <el-link :href="`${base.url}/service.html`" target="_blank" :underline="false">
                                            <span class="brandColor" style="font-size: 14px">《YinoCloud用户服务协议》</span>
                                        </el-link>
                                    </div>
                                </el-row>
                                <el-form-item>
                                    <el-button type="primary" style="width: 100%" @click="PhoneLogin">登录
                                    </el-button>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                        <!-- 账号密码表单登录区 -->
                        <el-tab-pane label="账号密码登录" name="accountLogin">
                            <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px">
                                <el-form-item prop="username">
                                    <el-input v-model="loginForm.username" placeholder="请输入手机号" clearable
                                        :validate-event="false">
                                        <template #prefix>
                                            <img src="@/assets/images/start/phone.svg" alt="">
                                        </template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item prop="password">
                                    <el-input v-model="loginForm.password" type="password" placeholder="请输入密码"
                                        show-password clearable :validate-event="false">
                                        <template #prefix>
                                            <img src="@/assets/images/start/password.svg" alt="">
                                        </template>
                                    </el-input>
                                </el-form-item>
                                <el-row align="middle" style="margin-bottom: 24px;">
                                    <el-checkbox v-model="agreeYino" label="" class="agree-checkbox"></el-checkbox>
                                    <div class="agree-box">
                                        <span class="Text">登录即表示同意</span>
                                        <el-link :href="`${base.url}/service.html`" target="_blank" :underline="false">
                                            <span class="brandColor" style="font-size: 14px">《YinoCloud用户服务协议》</span>
                                        </el-link>
                                    </div>
                                </el-row>
                                <el-form-item>
                                    <el-button type="primary" style="width: 100%" @click="login"
                                        :disabled="!agreeYino">登录
                                    </el-button>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                    <div class="footer">没有账号？<span @click="currentType = 'register'">去注册</span></div>
                </div>
                <!-- 微信扫码登录区域 -->
                <div class="wx-login" v-else>
                    <img src="@/assets/images/start/pc-login.svg" alt="" class="type" @click="loginType = 'pcLogin'">
                    <div class="title">欢迎使用YinoCloud易诺云</div>
                    <div class="codeBox" style="margin-bottom: 40px">
                        <el-row justify="center" style="margin-bottom: 30px">
                            <img :src="wxUrl" alt="" class="qrcodeImg" />
                        </el-row>
                        <el-row justify="center" align="middle">
                            <span class="Text">打开微信扫一扫登录</span>
                        </el-row>
                    </div>
                    <el-row align="middle" justify="center" style="margin-bottom: 24px;">
                        <el-checkbox v-model="agreeYino" label="" class="agree-checkbox"></el-checkbox>
                        <div class="agree-box">
                            <span class="Text">登录即表示同意</span>
                            <el-link :href="`${base.url}/service.html`" target="_blank" :underline="false">
                                <span class="brandColor" style="font-size: 14px">《YinoCloud用户服务协议》</span>
                            </el-link>
                        </div>
                    </el-row>
                </div>
            </div>
            <div class="register-container" v-else>
                <div class="content">
                    <div class="title-box">
                        <div class="title">欢迎使用YinoCloud易诺云</div>
                        <div class="sub-title">专业的一站式海外广告智能管理系统</div>
                    </div>
                    <div class="register-title">注册</div>
                    <!-- 注册区域 -->
                    <div class="registerForm">
                        <el-form ref="registerFormRef" :model="registerForm" :rules="registerFormRules"
                            label-width="0px" class="login-form">
                            <el-form-item prop="phone">
                                <el-input placeholder="请输入您的手机号" v-model="registerForm.phone" clearable
                                    :validate-event="false">
                                    <template #prefix>
                                        <img src="@/assets/images/start/phone.svg" alt="">
                                    </template>
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="phoneCode">
                                <el-row :gutter="20">
                                    <el-col :span="16" style="padding-right: 0;">
                                        <el-input v-model="registerForm.phoneCode" placeholder="请输入您的验证码" clearable
                                            :validate-event="false">
                                            <template #prefix>
                                                <img src="@/assets/images/start/code.svg" alt="">
                                            </template>
                                        </el-input>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-button :disabled="!checkPhone(registerForm.phone)" @click="getCode"
                                            class="code-button">
                                            {{ countDownTime > 0 ? `已发送${countDownTime}s` : '获取验证码' }}
                                        </el-button>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input placeholder="请输入您的密码" v-model="registerForm.password" type="password"
                                    show-password clearable :validate-event="false">
                                    <template #prefix>
                                        <img src="@/assets/images/start/password.svg" alt="">
                                    </template>
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="password1">
                                <el-input placeholder="请再次输入您的密码" v-model="registerForm.password1" type="password"
                                    show-password clearable :validate-event="false">
                                    <template #prefix>
                                        <img src="@/assets/images/start/password.svg" alt="">
                                    </template>
                                </el-input>
                            </el-form-item>
                            <el-form-item class="agree">
                                <el-row>
                                    <el-checkbox v-model="agreeYino" style="margin-right: 5px"></el-checkbox>
                                    <div class="agreeBox">
                                        <span class="Text">{{ isMobile ? '登录即表示已阅读并同意' : '登录即表示同意' }}</span>
                                        <el-link :href="`${base.url}/service.html`" target="_blank" :underline="false">
                                            <span class="brandColor" style="font-size: 14px">《YinoCloud用户服务协议》</span>
                                        </el-link>
                                    </div>
                                </el-row>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" style="width: 100%" @click="PhoneRegister"
                                    :size="innerWidth < 1600 ? 'small' : ''">注册
                                </el-button>
                            </el-form-item>
                        </el-form>
                        <div class="footer">
                            <span @click="currentType = 'login'">使用已有账号登录</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>

    <!-- 新注册绑定手机号弹窗 -->
    <el-dialog v-model="bindNewPhoneDialogVisible" :close-on-click-modal="false" :show-close="false" title="绑定手机号"
        width="30%" top="30vh">
        <el-form ref="bindNewPhoneFormRef" :model="bindNewPhoneForm" :rules="bindNewPhoneFormRules">
            <el-form-item label="" prop="phone">
                <el-input prefix-icon="el-icon-phone-outline" size="medium" placeholder="请输入手机号"
                    v-model.trim="bindNewPhoneForm.phone" clearable :validate-event="false"></el-input>
            </el-form-item>
            <el-form-item prop="phoneCode">
                <el-row :gutter="10">
                    <el-col :span="17">
                        <el-input prefix-icon="el-icon-chat-dot-round" size="medium" placeholder="请输入验证码"
                            v-model="bindNewPhoneForm.phoneCode" clearable :validate-event="false"></el-input>
                    </el-col>
                    <el-col :span="7" style="text-align: right">
                        <el-button size="medium" :disabled="!checkPhone(bindNewPhoneForm.phone)" @click="getBindCode">
                            {{ countDownBindTime > 0 ? `已发送${countDownBindTime}s` : '获取验证码' }}
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button size="medium" type="primary" @click="bindNewPhoneBtn">绑 定</el-button>
            </span>
        </template>
    </el-dialog>

    <!-- 添加企微弹窗 -->
    <el-dialog custom-class="addWXUser" v-if="'add-wx-user' === dialogType" v-model="showDialog" title="" width="430px"
        :show-close="false">
        <img src="@/assets/images/close.png" alt="" class="dialog-close" @click="closeAndRefresh">
        <img src="@/assets/images/skip.png" alt="" class="dialog-skip" @click="closeAndRefresh">
        <img class="server_qrcode" :src="live_code" alt="" />
    </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import base from '@/request/base'

const { proxy } = getCurrentInstance()
const isDialogShow = ref(true) // 登录注册弹窗
const showDialog = ref(false) // 添加企微弹窗
const dialogType = ref('') // 添加企微弹窗类型
const bindNewPhoneDialogVisible = ref(false) // 新注册绑定手机号弹窗
const phoneLoginFormRef = ref(null)
const loginFormRef = ref(null)
const registerFormRef = ref(null)
const bindNewPhoneFormRef = ref(null)
const currentType = ref('register')
const loginType = ref('pcLogin')
const loginActiveName = ref('phoneLogin')
const agreeYino = ref(true)
const countDownTime = ref(0) // 验证码倒计时
const countDownBindTime = ref(0) // 绑定验证码倒计时
const wxUrl = ref('') // 微信二维码图片
const wxKey = ref('') // 微信二维码key
const wxClock = ref(null) // 微信二维码定时器
const userId = ref('')
const live_code = ref('')
const userInfo = ref({})

const loginForm = ref({
    username: '',
    password: '',
    token: localStorage.getItem('qbit-token')
})
const phoneLoginForm = ref({
    phone: '',
    phoneCode: '',
    type: 'code',
    token: localStorage.getItem('qbit-token')
})
const registerForm = ref({
    type: 'code',
    phone: '',
    phoneCode: '',
    password: '',
    password1: '',
})
const loginFormRules = ref({
    username: [{ required: true, message: '请输入登录手机号' }],
    password: [
        { required: true, message: '请输入登录密码' },
        {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符'
        },
    ],
})
const checkMobile = (rule, value, cb) => {
    const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/

    if (regMobile.test(value)) {
        return cb()
    }

    cb(new Error('请输入有效的电话号码'))
}
const phoneLoginFormRules = ref({
    phone: [
        { required: true, message: '请输入手机号' },
        { validator: checkMobile },
    ],
    phoneCode: [{ required: true, message: '请输入验证码' }],
})
const registerFormRules = ref({
    phone: [
        { required: true, message: '请输入手机号' },
        { validator: checkMobile },
    ],
    phoneCode: [{ required: true, message: '请输入验证码' }],
    password: [
        { required: true, message: '请输入密码' },
        {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}$/,
            message: '必须包含大小写字母和数字的组合，可以使用特殊字符，长度在 6-12 之间'
        },
    ],
    password1: [
        { required: true, message: '请再次输入密码' },
        {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}$/,
            message: '必须包含大小写字母和数字的组合，可以使用特殊字符，长度在 6-12 之间'
        },
    ],
})
const bindNewPhoneForm = ref({
    phone: '',
    phoneCode: '',
})
const bindNewPhoneFormRules = ref({
    phone: [
        { required: true, message: '请输入手机号' },
        { validator: checkMobile },
    ],
    phoneCode: [{ required: true, message: '请输入正确的验证码' }],
})


/** 校验手机号是否能发送验证码 */
const checkPhone = (value) => {
    return /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/.test(value)
}

/** 获取验证码 */
const getCode = async () => {
    // 如果当前没有计时
    if (!countDownTime.value) {
        // 启动倒计时
        countDownTime.value = 60
        const clock = window.setInterval(() => {
            countDownTime.value--
            if (countDownTime.value <= 0) {
                clearInterval(clock)
            }
        }, 1000)

        await proxy.$http.post('login/login', currentType.value === 'register' ? registerForm.value : phoneLoginForm.value)
    }
}

/** 获取绑定手机验证码 */
const getBindCode = async () => {
    // 如果当前没有计时
    if (!countDownBindTime.value) {
        // 启动倒计时
        countDownBindTime.value = 60
        const clock = window.setInterval(() => {
            countDownBindTime.value--
            if (countDownBindTime.value <= 0) {
                clearInterval(clock)
            }
        }, 1000)

        await proxy.$http.post('login/login', {
            phone: bindNewPhoneForm.value.phone,
            type: 'code',
        })
    }
}

/** 关闭添加企微弹窗 */
const closeAndRefresh = () => {
    dialogType.value = ''
    if (proxy.$store.getters.userInfo.crm_id > 0 || proxy.$store.getters.userInfo.q_type > 0) {
        if (proxy.$route.query.goto) return proxy.$router.push(proxy.$route.query.goto)
        proxy.$router.push('/home')
    } else {
        proxy.$router.push('/users')
    }
}

/** 添加企微  */
const addWxUser = async (res, refresh = true) => {
    localStorage.removeItem('qbit-token')
    if (!res.user || !res.user.phone || res.user.q_type === 1 || res.user.exc === 'qbit') {
        window.localStorage.setItem('userInfo', JSON.stringify(res.user))
        proxy.$store.dispatch('EDIT_USERINFO', res.user)
        if (refresh) closeAndRefresh()
        return
    }

    const result = await proxy.$http.get('https://scrm-wx.weiling.cn/client/mobile/corp_friend', {
        params: {
            corp_id: 'wpW2WmDAAAjT9PP65h63Ilm7Xlbvg4qg',
            mobile: !res.user || !res.user.phone ? '' : res.user.phone,
        },
    })

    if (result.code == 200 && result.data == true) {
        window.localStorage.setItem('userInfo', JSON.stringify(res.user))
        proxy.$store.dispatch('EDIT_USERINFO', res.user)
        if (refresh) closeAndRefresh()
        return
    }

    let name = res.user.name == null ? '' : res.user.name
    let phone = res.user.phone == null ? '' : res.user.phone
    let company = res.user.company == null ? '' : res.user.company
    let email = res.user.email == null ? '' : res.user.email
    wlRegisterComp.linkToWL(
        {
            wl_qrcode_id: '1605811139499880448', // 需要使用的智能码ID
            client_user_id: res.user.phone, // 用户在贵公司平台的唯一
            sign_mode: false, // 可选参数 不传时默认false 是否校验签名
            sign: '', //string 可选参数 sign_mode为true时传输 加密后的签名
            current_time: 0, //可选参数 sign_mode为true时传输 加密签名时使用的毫秒时间戳
            formData: {
                联系人: name,
                手机号: phone,
                公司名称: company,
                邮箱: email,
            }, // 用户填写的注册或登录信息，value可以为空，但key值必填
            is_update: false, // 此次提交是否为数据更新，默认false
        },
        (data) => {
            if (data.msg == '当前用户已加好友' || data.code !== 200) {
                window.localStorage.setItem('userInfo', JSON.stringify(res.user))
                proxy.$store.dispatch('EDIT_USERINFO', res.user)
                if (refresh) closeAndRefresh()
                return
            }
            if (data.code !== 200) {
                window.localStorage.setItem('userInfo', JSON.stringify(res.user))
                proxy.$store.dispatch('EDIT_USERINFO', res.user)
                return
            }
            live_code.value = data.data.live_code
            wlRegisterComp.checkUserState(
                res.user.phone, // 用户在客户平台的唯一ID
                (data) => {
                    window.localStorage.setItem('userInfo', JSON.stringify(res.user))
                    proxy.$store.dispatch('EDIT_USERINFO', res.user)
                    if (data.data.is_added == false) {
                        dialogType.value = 'add-wx-user'
                        showDialog.value = true
                    } else {
                        dialogType.value = ''
                        showDialog.value = false
                    }
                }
            )
        }
    )
}

/** 获取微信二维码 */
const wxImg = async () => {
    const { data: res } = await proxy.$http.get('login/wxImg')
    wxUrl.value = res.data.url
    wxKey.value = res.data.key
    wxLogin()
}

/** 微信登录 */
const wxLogin = () => {
    wxClock.value = window.setInterval(async () => {
        const { data: res } = await proxy.$http.post('login/wxLogin', {
            titcket: wxKey.value,
            token: localStorage.getItem('qbit-token'),
        })
        if (res.code == 200) {
            clearInterval(wxClock.value)
            if (res.user.phone == '' || res.user.phone == null) {
                userId.value = res.user.id
                bindNewPhoneDialogVisible.value = true
            } else {
                let trace = window.localStorage.getItem('trace')
                if (trace) {
                    const traceDate = await proxy.$http.post('login/trace', {
                        key: trace,
                        type: '登录',
                        token: res.user.token,
                    })
                }
                let source = window.localStorage.getItem('source')
                if (source) {
                    let routeData = proxy.$router.resolve({ path: '/wj' })
                    window.open(routeData.href, '_blank')
                }
                await addWxUser(res)
            }
        }
    }, 1000)
}

/** 获取微信绑定二维码 */
const wxBindImg = async () => {
    const { data: res } = await proxy.$http.get('login/wxImg')
    wxUrl.value = res.data.url
    wxKey.value = res.data.key
    wxBindLogin()
}

/** 绑定微信后登录 */
const wxBindLogin = () => {
    wxClock.value = window.setInterval(async () => {
        const { data: res } = await proxy.$http.post('login/edit_whchat', {
            titcket: wxKey.value,
            user_id: userId.value,
            type: 3,
        })
        if (res.code == 100) {
            return proxy.$message.error(`${res.msg}`)
        }
        if (res.code == 300) {
            clearInterval(wxClock.value)
            proxy.$router.push('/login')
        }
        if (res.code == 200) {
            let trace = window.localStorage.getItem('trace')
            if (trace) {
                const traceDate = await proxy.$http.post('login/trace', { key: trace, type: '注册', token: res.user.token })
            }
            clearInterval(wxClock.value)
            proxy.$message.success('绑定成功')
            await addWxUser(res)
        }
    }, 1000)
}

/** 密码登录 */
const login = () => {
    loginFormRef.value.validate(async (vaild) => {
        if (!vaild) return
        const { data: res } = await proxy.$http.post('login/p_login', loginForm.value)
        userInfo.value = res.user

        if (res.code != 200) return proxy.$message.error(`${res.msg}`)
        let trace = window.localStorage.getItem('trace')
        if (trace) {
            const traceDate = await proxy.$http.post('login/trace', { key: trace, type: '登录', token: res.user.token })
        }
        let source = window.localStorage.getItem('source')
        if (source) {
            let routeData = proxy.$router.resolve({ path: '/wj' })
            window.open(routeData.href, '_blank')
        }
        // 将登录成功后的token，保存到客户端的localStorage中
        await addWxUser(res)
    })
}

/** 验证码登录 */
const PhoneLogin = () => {
    phoneLoginFormRef.value.validate(async (vaild) => {
        if (!vaild) return
        const { data: res } = await proxy.$http.post('login/login', {
            phone: phoneLoginForm.value.phone,
            phoneCode: phoneLoginForm.value.phoneCode,
            token: phoneLoginForm.value.token,
        })

        if (res.code != 200) return proxy.$message.error(`${res.msg}`)
        let trace = window.localStorage.getItem('trace')
        if (trace) {
            const traceDate = await proxy.$http.post('trace', { key: trace, type: '登录', token: res.user.token })
        }
        let source = window.localStorage.getItem('source')
        if (source) {
            let routeData = proxy.$router.resolve({ path: '/wj' })
            window.open(routeData.href, '_blank')
        }
        await addWxUser(res)
    })
}

/** 绑定手机号 */
const bindNewPhoneBtn = () => {
    bindNewPhoneFormRef.value.validate(async (valid) => {
        if (!valid) return

        const { data: res } = await proxy.$http.post('login/edit_phone', {
            phone: bindNewPhoneForm.value.phone,
            phoneCode: bindNewPhoneForm.value.phoneCode,
            user_id: userId.value,
            type: 3,
        })
        if (res.code == 100) return proxy.$message.error(`${res.msg}`)
        if (res.code == 300) {
            proxy.$router.push('/login')
        }
        let trace = window.localStorage.getItem('trace')
        if (trace) {
            const traceDate = await proxy.$http.post('login/trace', { key: trace, type: '注册', token: res.user.token })
        }
        proxy.$message.success('绑定成功')
        await addWxUser(res)
    })
}

/** 注册 */
const PhoneRegister = () => {
    registerFormRef.value.validate(async (vaild) => {
        if (!vaild) return
        const { data: res } = await proxy.$http.post('login/register', {
            phone: registerForm.value.phone,
            phoneCode: registerForm.value.phoneCode,
            password: registerForm.value.password,
            password1: registerForm.value.password1,
            token: location.search.split('token=').length > 1 ? location.search.split('token=')[1] : '',
            source: 'YCGW',
        })
        if (res.code != 200) return proxy.$message.error(`${res.msg}`)
        // 百度转化代码安装-表单提交类
        window._agl && window._agl.push(['track', ['success', { t: 3 }]])
        // this.$message.success('登录成功')
        addWxUser(res)
    })
}

</script>

<style lang="less">
.start-login-dialog {
    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        height: 654px;
        padding: 0;
        background: url('../../assets/images/start/bg.png') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.addWXUser {
    height: 560px !important;
    background: url('~@/assets/images/popBG.png') no-repeat;
    background-size: 100%;
    position: relative;

    .el-dialog__header {
        padding: 0 !important;
        border: 0 !important;
    }

    .el-dialog__body {
        padding: 0 !important;
    }

    .dialog-close {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .dialog-skip {
        width: 108px;
        height: 40px;
        position: absolute;
        bottom: 38px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }

    .server_qrcode {
        width: 268px;
        height: 268px;
        position: absolute;
        top: 180px;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>
<style lang="less" scoped>
.left-container {
    flex: 1;
    position: relative;
    height: 100%;

    img:nth-child(1) {
        position: absolute;
        top: 48px;
        left: 48px;
    }

    img:nth-child(2) {
        width: 514px;
        height: 364px;
        position: absolute;
        bottom: 64px;
        left: 0;
    }
}

.right-container {
    flex: 1;
    position: relative;
    height: 100%;

    .close {
        position: absolute;
        top: 0;
        right: -44px;
        width: 36px;
        height: 36px;
        background: #FEFEFE;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .code-button {
        padding: 10px 28px;
        border: 1px solid #858EBD;
        background: transparent;
        color: #858EBD
    }

    .footer {
        width: 100%;
        text-align: center;
        margin-top: 2px;
        color: #666666;

        span {
            color: #2b82ff;
            cursor: pointer;
        }
    }

    .login-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .type {
            position: absolute;
            top: -1px;
            right: -1px;
            cursor: pointer;
        }

        .title {
            font-family: PingFang SC-Regular;
            font-weight: 400;
            font-size: 36px;
            color: #333333;
            margin-bottom: 32px;
        }

        .agree-checkbox {
            margin-right: 8px;
            height: 20px;
        }

        .agree-box {
            display: flex;
            align-items: center;
            font-size: 14px;
        }

        .qrcodeImg {
            width: 200px;
            height: 200px;
        }

        :deep(.el-tabs__header) {
            margin-bottom: 40px;
        }

        :deep(.el-tabs__item.is-active) {
            font-weight: 500 !important;
            color: #2b82ff !important;
        }

        :deep(.el-tabs__item) {
            font-weight: 400 !important;
            color: #333333 !important;
        }

        :deep(.el-tabs__nav-wrap::after) {
            background-color: #F5F5F5;
        }

    }

    .register-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .title-box {
            .title {
                font-size: 36px;
                font-weight: 400;
                color: #333333;
                margin-bottom: 16px;
            }

            .sub-title {
                font-size: 18px;
                color: #666666;
                text-align: center;
                margin-bottom: 24px;
            }
        }

        .register-title {
            font-size: 24px;
            color: #333333;
            text-align: center;
            margin-bottom: 24px;
        }

        .registerForm {
            width: 440px;
        }
    }

    :deep(.el-input__inner) {
        border: 1px solid #858EBD;
        padding-left: 42px;
        background: transparent;
    }

    :deep(.el-input__inner::placeholder) {
        color: #858EBD;
    }

    :deep(.el-input__prefix) {
        left: 16px;
        display: flex;
        align-items: center;
    }
}
</style>