<template>
    <div class="flex-col page">
        <div class="header">
            <img class="image" src="@/assets/images/start/logo.svg" />
            <div class="top-button" @click="isDialogShow = true">注册/登录</div>
        </div>
        <section>
            <div class="flex-col section">
                <video autoplay loop muted src="@/assets/images/start/bg.mp4"></video>
                <div class="flex-row justify-between self-stretch section-top">
                    <img class="image" src="@/assets/images/start/logo-white.png" />
                    <div class="top-button" @click="isDialogShow = true">注册/登录</div>
                </div>
                <div class="title-container">
                    <span class="text animate__animated animate__fadeInLeft">YinoCloud易诺云</span>
                    <div class="flex-row items-baseline self-stretch group">
                        <span class="shrink-0 text_2 animate__animated animate__fadeInUp">一站式海外广告智能管理系统</span>
                        <span
                            class="font text_3 ml-39 animate__animated animate__fadeInRight">快速跑通出海营销链路，提升广告投放能力，实现效益增长</span>
                    </div>
                </div>
                <div class="self-center center-button" @click="isDialogShow = true">
                    <span>注册/登录</span>
                    <span>注册/登录</span>
                </div>
                <div class="self-center next-button" @click="goSecondSection"></div>
            </div>
        </section>
        <section class="group_2" id="section2">
            <div class="flex-row group_3 justify-center">
                <div class="flex-col self-start group_4">
                    <span class="self-start font_2 text_4">YinoCloud易诺云</span>
                    <span class="self-stretch font_3 text_5">
                        YinoCloud易诺云集合海外广告账户开户与充值、素材制作、广告情报数据、广告效果自动优化工具、海外营销知识培训等服务与功能，帮助出海广告主快速跑通海外营销链路，提升出海投放能力，实现效益增长。
                    </span>
                    <div class="button" @click="isDialogShow = true">注册开户
                        <img src="@/assets/images/start/arrow-right.svg" alt="">
                    </div>
                    <span class="self-start font_4 text_6">注册认证后将有专业顾问1v1为您解答出海营销问题。</span>
                    <div class="self-start section_2"></div>
                    <div class="flex-row self-stretch group_5">
                        <img class="shrink-0 image_6" src="@/assets/images/start/two-section-left.png" />
                        <span class="text_7">超10000+ 出海广告主使用YinoCloud易诺云，立即加入吧！</span>
                    </div>
                </div>
                <div class="flex-col justify-start items-end image-wrapper ml-69">
                    <img class="image_4" src="@/assets/images/start/two-section-right.png" />
                </div>
            </div>
        </section>
        <section class="section3">
            <div class="section3-continer">
                <picker class="picker" ref="pickerRef" />
                <div class="card-list">
                    <div class="flex-col items-center group_6">
                        <span class="text_8">我们能做什么</span>
                        <span class="font_5 text_9 mt-21">Core Services</span>
                    </div>
                    <div>
                        <div class="title">广告开户</div>
                        <div class="content">拥有Facebook、TikTok、Bing、Google等海外主流社交媒体代理资源，注册后简单认证即可开通账户，最快当天下户</div>
                        <div class="center-button" @click="isDialogShow = true">注册开户</div>
                    </div>
                    <div>
                        <div class="title">账户管理</div>
                        <div class="content">支持人民币美金双币多平台充值方式、广告账户像素绑定/解绑、自助余额清零、广告投放数据以及成效分析</div>
                        <div class="center-button" @click="isDialogShow = true">注册开户</div>
                    </div>
                    <div>
                        <div class="title">素材模板</div>
                        <div class="content">创意素材一键套用，批量制作省心省力</div>
                        <div class="center-button" @click="isDialogShow = true">注册/登录</div>
                    </div>
                    <div>
                        <div class="title">效果优化</div>
                        <div class="content">无需设置受众与目标，使用YinoCloud易诺云广告投放模板，新手也能轻松投放</div>
                        <div class="center-button" @click="isDialogShow = true">注册/登录</div>
                    </div>
                    <div>
                        <div class="title">跨境情报</div>
                        <div class="content">联合跨境业内优质数据类合作伙伴，为出海广告主提供素材、流量、选品等全球跨境市场情报</div>
                        <div class="center-button" @click="isDialogShow = true">注册/登录</div>
                    </div>
                    <div>
                        <div class="title">知识培训</div>
                        <div class="content">为不同阶段、不同市场、不同业务的中国出海卖家提供全面而深入的海外营销知识体系支持</div>
                        <div class="center-button" @click="isDialogShow = true">了解更多</div>
                    </div>
                </div>
            </div>
        </section>

        <div class="flex-row group_11">
            <div class="flex-col items-center self-center group_12">
                <div class="image_9">
                    <img src="@/assets/images/start/four-section-yxgh.png" alt="">
                    <img src="@/assets/images/start/yxgh.png" alt="">
                    <div>
                        <span class="title">营销干货</span>
                        <span
                            class="text">不断更新跨境行业市场洞察、投放攻略、案例解读等文档指南，内容涵盖Facebook、TikTok、Bing、Google等主流媒体，行业包括跨境电商、APP出海、游戏出海</span>
                    </div>
                </div>
                <div class="image_9">
                    <img src="@/assets/images/start/four-section-kcyhd.png" alt="">
                    <img src="@/assets/images/start/kcyhd.png" alt="">
                    <div>
                        <span class="title">课程与活动</span>
                        <span
                            class="text">专业海外广告投放团队系列精品课程、系列广告直播课，定期邀请行业内专业讲师、优质卖家、官方直客参与线上直播或线下沙龙，为出海广告主持续输出最新市场趋势，解答营销问题</span>
                    </div>
                </div>
                <div class="image_9">
                    <img src="@/assets/images/start/four-section-zsgw.png" alt="">
                    <img src="@/assets/images/start/zsgw.png" alt="">
                    <div>
                        <span class="title">专属顾问</span>
                        <span class="text">注册认证后对接专属商务协助解决开户问题，3年以上海外营销资质优化师进群指导，账户投放问题与方案随时咨询</span>
                    </div>
                </div>
            </div>
            <div class="section_7"></div>
            <div class="flex-col self-start group_13">
                <span class="self-start font_2 text_17">赋能效果增长</span>
                <span class="self-start font_3 text_18">
                    YinoCloud易诺云汇集帮助中心、YinoLink周5出海营销知识资源，协助新手广告主解决从0-1的入门问题，培育广告主由1-100的长效优化能力。
                </span>
                <div class="flex-col justify-start items-start self-stretch relative group_14">
                    <div class="button" @click="isDialogShow = true">注册/登录
                        <img src="@/assets/images/start/arrow-right.svg" alt="">
                    </div>
                    <img class="image_10 pos_3" src="@/assets/images/start/four-section-right.png" />
                </div>
            </div>
        </div>
        <div class=" flex-col section_8">
            <img class="self-start image_12" src="@/assets/images/start/logo.svg" />
            <div class="flex-row justify-between items-end self-stretch group_15">
                <div class="flex-col group_16">
                    <div class="flex-col items-start">
                        <span class="font_8 text_20">YinoCloud易诺云</span>
                        <span class="mt-18 font_8 text_21">一站式海外广告智能管理系统</span>
                    </div>
                    <span class="mt-44 font_6 text_22">
                        YinoCloud易诺云是YinoLink易诺旗下的一站式海外广告智能管理系统，包含多个高效管理模块，为出海营销卖家提供海外广告账户开户与充值、素材制作、广告情报数据、广告效果自动优化工具、海外营销知识培训等服务与功能，帮助出海广告主快速跑通海外营销链路，提升出海投放能力，实现效益增长。
                    </span>
                </div>
                <div class="flex-col items-start group_17">
                    <img class="image_13" :src="wlQrcode" />
                    <span class="font_7 text_23 mt-21">企业微信二维码</span>
                </div>
            </div>
            <div class="flex-col self-stretch group_18">
                <ul class="footerLink">
                    <router-link to="/hc" tag="a" target="_blank"><span>帮助</span></router-link>
                    <el-link href="https://yinocloud.yinolink.com/privacy.html" :underline="false"
                        target="_blank"><span>隐私</span></el-link>
                    <el-link :underline="false" href="https://yinocloud.yinolink.com/service.html"
                        target="_blank"><span>条款</span></el-link>
                </ul>
                <div class="mt-32 flex-row justify-center items-center">
                    <span class="font_4 text_27">杭州益刻网络科技有限公司版权所有Copyright © 2020</span>
                    <img class="image_14 ml-7" src="@/assets/officeIcon.png" />
                    <span class="font_4 ml-7">沪ICP备20011628号-1</span>
                </div>
            </div>
        </div>
    </div>

    <LoginDialog v-if="isDialogShow" @close="isDialogShow = false" />
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, onUnmounted } from 'vue';
import LoginDialog from './LoginDialog.vue';
import Picker from './Picker.vue';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import 'animate.css'
import baidu from '@/base/baidu-tracking.js'

const { proxy } = getCurrentInstance();

gsap.registerPlugin(ScrollTrigger);

const pickerRef = ref(null);
const isDialogShow = ref(false);
const wlQrcode = ref(null);

; (function () {
    let wl = document.createElement("script")
    wl.type = "text/javascript"
    wl.src = "https://material.weiling.cn/h5/willing-third-party-js/v1/qrcode-custom-comp-v1.min.js"
    wl.id = "wlQRCS"
    wl.onload = function () {
        wlQrcodeCustomComp.init({
            wl_id: "wpW2WmDAAAjT9PP65h63Ilm7Xlbvg4qg" // [修改1：将wl_id粘贴至此]
        })
    }
    if (!document.getElementById("wlQRCS")) {
        let s = document.getElementsByTagName("script")[0]
        s.parentNode.insertBefore(wl, s)
    }
})()
window.onload = function () {
    wlQrcodeCustomComp.getQrCodeUrl(
        {
            wl_qrcode_id: "1764898881298268160" //  [修改1：将wl_qrcode_id粘贴至此]
        },
        (data) => {
            // data中包含二维码图片地址
            if (data && data.code === 200) {
                wlQrcode.value = data.data.qr_code
            }
        }
    )
}

const goSecondSection = () => {
    document.querySelector('#section2').scrollIntoView({ behavior: 'smooth' })
}

onMounted(() => {
    if (window.innerWidth <= 1200) {
        proxy.$router.push('/startMobile')
        return
    }

    ScrollTrigger.create({
        trigger: '.section3',
        start: 'top 20%', //触发效果的位置
        end: 'bottom bottom', // 触发动画结束的位置
        // markers: true,
        scrub: true, // 动画进度关联滚动条
        // pin: true, // 固定指定元素
        pinSpacing: true, // 固定元素的高度是否占位，flex布局下会变为false
        animation: gsap.timeline().to('.card-list>div:nth-child(3)', { opacity: 1, rotationX: 0 })
            .to('.card-list>div:nth-child(4)', { opacity: 1, rotationX: 0 })
            .to('.card-list>div:nth-child(5)', { opacity: 1, rotationX: 0 })
            .to('.card-list>div:nth-child(6)', { opacity: 1, rotationX: 0 })
            .to('.card-list>div:nth-child(7)', { opacity: 1, rotationX: 0 })
    })

    ScrollTrigger.create({
        trigger: '.picker',
        start: 'bottom center', //触发效果的位置
        end: '2600px', // 触发动画结束的位置
        // markers: true, // 开启位置调试
        scrub: true, // 动画进度关联滚动条
        pin: true, // 固定指定元素
    })

    // 监听滚动，有滚动让header显示，否则隐藏
    let timer = null;
    window.addEventListener('scroll', () => {
        clearTimeout(timer);
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let header = document.querySelector('.header');
        if (scrollTop > 0) {
            header.style.top = '0';
            header.style.opacity = '1';
            timer = setTimeout(() => {
                header.style.top = '-80px';
                header.style.opacity = '0.5';
            }, 2000);
        } else {
            header.style.top = '-80px';
            header.style.opacity = '0.5';
        }
    })

    // 当.card-list中的每个进入视口时，切换picker的值
    const cardList = document.querySelectorAll('.card-list>div');
    cardList.forEach((item, index) => {
        ScrollTrigger.create({
            trigger: item,
            start: 'top 50%',
            end: 'bottom 50%',
            onEnter: () => {
                if (index === 0) return;
                pickerRef.value.chooseLi(index - 1)
            }
        })
        ScrollTrigger.create({
            trigger: item,
            start: 'center 50%',
            end: 'bottom 50%',
            onLeaveBack: () => {
                if (index === 0) return;
                pickerRef.value.chooseLi(index - 1)
            }
        })
    })
})

onUnmounted(() => {
    window.removeEventListener('scroll', () => { })
})
</script>

<style scoped lang="less">
.ml-39 {
    margin-left: 39px;
}

.mt-99 {
    margin-top: 99px;
}

.ml-69 {
    margin-left: 69px;
}

.ml-3 {
    margin-left: 3px;
}

.mt-120 {
    margin-top: 120px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-21 {
    margin-top: 21px;
}

.ml-7 {
    margin-left: 7px;
}

.page {
    position: relative;
    width: 100%;
    height: 100%;

    .header {
        box-sizing: border-box;
        position: fixed;
        top: -80px;
        left: 0;
        height: 80px;
        width: 100vw;
        background-color: #ffffff;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 120px;
        opacity: .5;
        transition: all .8s;
        border-bottom: 1px solid #dcdfe6;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);


        .image {
            width: 220px;
            height: 40px;
        }

        .top-button {
            width: 109px;
            height: 40px;
            border-radius: 152px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            font-family: 'PingFang SC-Regular';
            background: #2b82ff;
            color: #ffffff;
        }

    }

    section {
        background: #ffffff;
        position: relative;
        min-height: 100vh;
    }

    .section {
        * {
            z-index: 1;
        }

        height: 100%;
        position: relative;

        video {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            object-fit: cover;
            z-index: 0;
        }

        .section-top {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            box-sizing: border-box;
            padding: 20px 120px;
        }

        .top-button {
            width: 109px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 152px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            font-family: 'PingFang SC-Regular';
            color: #333333;

            &:hover {
                background: #2b82ff;
                color: #ffffff;
                transition: all 0.5s;
            }
        }

        .image {
            width: 220px;
            height: 40px;
        }

        .title-container {
            width: 100%;
            position: absolute;
            top: 28%;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .text {
            color: #ffffff;
            font-size: 152px;
            font-family: 'PingFang SC-Regular';
            line-height: 142px;
        }

        .group {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            .text_2 {
                color: #ffffff;
                font-size: 64px;
                font-family: 'PingFang SC-Regular';
            }

            .text_3 {
                margin-top: 8px;
                font-weight: 300;
                font-size: 22px;
                text-align: justify;
                width: 286px;
            }
        }

        .center-button {
            width: 112px;
            height: 40px;
            border: 2px solid #FFFFFF;
            background: transparent;
            border-radius: 152px;
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            font-family: 'PingFang SC-Regular';
            font-weight: 500;
            color: #ffffff;
            margin-top: 102px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            position: absolute;
            bottom: 20%;


            span:nth-child(1) {
                line-height: 40px;
                position: absolute;
                top: 0;
                transition: all 1s;
            }

            span:nth-child(2) {
                position: absolute;
                top: 40px;
                transition: all 1s;
            }

            &:hover span:nth-child(1) {
                top: -40px;
            }

            &:hover span:nth-child(2) {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .next-button {
            position: absolute;
            bottom: 8%;
            margin-top: 86px;
            width: 22px;
            height: 40px;
            border-radius: 14px;
            border: 2px solid #505051;

            &:after {
                position: absolute;
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background-color: #ffffff;
                animation: jump 2s infinite;
            }

            @keyframes jump {
                0% {
                    top: 30%;
                }

                50% {
                    top: 70%;
                }

                100% {
                    top: 30%;
                }
            }
        }
    }

    .section3 {
        position: relative;
        min-height: fit-content;
        display: flex;
        justify-content: center;

        .section3-continer {
            width: 1175px;
            padding-bottom: 120px;
            display: flex;
            justify-content: flex-end;

            .card-list {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: flex-end;

                &>div:not(:first-child):not(:nth-child(2)) {
                    opacity: 0.4;
                    transform: perspective(400px) rotateX(30deg);
                }

                &>div:not(:first-child) {
                    position: relative;
                    width: 966px;
                    height: 458px;
                    border-radius: 16px;
                }

                &>div:not(:last-child) {
                    margin-bottom: 120px;
                }

                &>div:nth-child(2) {
                    background: url("~@/assets/images/start/swiper1.jpg") no-repeat;
                    background-size: 100% 100%;
                }

                &>div:nth-child(3) {
                    background: url("~@/assets/images/start/swiper2.png") no-repeat;
                    background-size: 100% 100%;
                }

                &>div:nth-child(4) {
                    background: url("~@/assets/images/start/swiper3.jpg") no-repeat;
                    background-size: 100% 100%;
                }

                &>div:nth-child(5) {
                    background: url("~@/assets/images/start/swiper4.jpg") no-repeat;
                    background-size: 100% 100%;
                }

                &>div:nth-child(6) {
                    background: url("~@/assets/images/start/swiper5.jpg") no-repeat;
                    background-size: 100% 100%;
                }

                &>div:nth-child(7) {
                    background: url("~@/assets/images/start/swiper6.png") no-repeat;
                    background-size: 100% 100%;
                }

                .group_6 {
                    width: 100%;
                    margin-top: 106px;
                    margin-bottom: 48px !important;

                    .text_8 {
                        color: #333333;
                        font-size: 42px;
                        font-family: PingFang SC;
                        line-height: 39px;
                    }

                    .font_5 {
                        font-size: 24px;
                        font-family: PingFang SC;
                    }

                    .text_9 {
                        color: #666666;
                        line-height: 18px;
                    }
                }

                .title {
                    position: absolute;
                    left: 80px;
                    top: 200px;
                    font-family: 'PingFang SC-Medium';
                    font-weight: 500;
                    font-size: 22px;
                    color: #FFFFFF;
                    line-height: 30px;
                }

                .content {
                    position: absolute;
                    left: 80px;
                    top: 246px;
                    width: 320px;
                    font-family: 'PingFang SC-Medium';
                    font-weight: 500;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 22px;
                    text-align: justify;
                }

                .center-button {
                    position: absolute;
                    left: 80px;
                    top: 336px;
                    width: 112px;
                    height: 32px;
                    border: 2px solid #FFFFFF;
                    background: transparent;
                    border-radius: 152px;
                    text-align: center;
                    cursor: pointer;
                    font-size: 14px;
                    font-family: 'PingFang SC-Regular';
                    font-weight: 500;
                    color: #ffffff;
                    line-height: 32px;
                }
            }
        }
    }

    .group_2 {
        padding: 0 120px;

        .group_3 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-top: solid 1px #dddddd;
            border-bottom: solid 1px #dddddd;

            .group_4 {
                width: 530px;
                padding: 10vh 0;

                .text_4 {
                    line-height: 52px;
                }

                .text_5 {
                    margin-top: 46px;
                    text-align: justify;
                }

                .button_2 {
                    align-self: flex-start;
                    margin-top: 40px;
                }

                .text_6 {
                    margin-top: 44px;
                    color: #999999;
                    line-height: 13px;
                    letter-spacing: 3px;
                }

                .section_2 {
                    margin-top: 44px;
                    background-color: #dddddd;
                    width: 380px;
                    height: 1px;
                }

                .group_5 {
                    margin-top: 40px;

                    .image_6 {
                        width: 152px;
                        height: 50px;
                    }

                    .text_7 {
                        margin-left: 20px;
                        color: #666666;
                        font-size: 14px;
                        font-family: 'PingFang SC-Regular';
                        line-height: 22px;
                        text-align: justify;
                        width: 210px;
                    }
                }
            }

            .image-wrapper {
                width: 600px;
                border-left: solid 1px #dddddd;
                padding: 10vh 0;

                .image_4 {
                    width: 530px;
                    height: 524px;
                }
            }
        }
    }

    .button {
        width: 258px;
        height: 58px;
        background: #2B82FF;
        border-radius: 213px;
        line-height: 58px;
        text-align: center;
        cursor: pointer;
        font-weight: 500;
        font-size: 24px;
        color: #FFFFFF;
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background: #333333;
            transition: all 0.8s;

            img {
                transform: translateX(10px);
                transition: all 0.8s;
            }
        }
    }

    .font {
        font-size: 22px;
        font-family: PingFang SC;
        color: #ffffff;
    }

    .group_11 {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;

        .group_12 {
            width: 670px;

            .image_9 {
                width: 530px;
                height: 146px;
                background: linear-gradient(#FFFFFF 0%, #F8F8F8 100%);
                border-radius: 8px;
                display: flex;
                padding: 12px 20px 20px 26px;
                box-sizing: border-box;
                margin-bottom: 24px;
                cursor: pointer;

                &:hover {
                    background: #2B82FF;
                    transition: all 0.5s;

                    img:nth-child(1) {
                        display: none;
                    }

                    img:nth-child(2) {
                        display: block;
                    }

                    span {
                        color: #ffffff;
                    }
                }

                img {
                    width: 48px;
                    height: 48px;
                    margin-right: 16px;
                }

                img:nth-child(2) {
                    display: none;
                }

                .title {
                    font-family: 'PingFang SC-Medium';
                    font-weight: 500;
                    font-size: 24px;
                    color: #333333;
                    margin-top: 8px;
                    margin-bottom: 8px;
                    display: inline-block;
                }

                .text {
                    font-size: 14px;
                    color: #666666;
                    line-height: 22px;
                    text-align: justified;
                    display: inline-block;
                }
            }
        }

        .section_7 {
            background-color: #dddddd;
            width: 1px;
            height: 765px;
        }

        .group_13 {
            margin-left: 69px;
            margin-top: 146px;
            width: 650px;

            .text_17 {
                margin-left: 2px;
            }

            .text_18 {
                margin-top: 46px;
                text-align: justify;
                width: 530px;
            }

            .group_14 {
                margin-top: 40px;
                padding-bottom: 340px;

                .image_10 {
                    width: 470px;
                    height: 390px;
                }

                .pos_3 {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }

    .font_2 {
        font-size: 56px;
        font-family: PingFang SC;
        line-height: 52.5px;
        color: #333333;
    }

    .font_3 {
        font-size: 20px;
        font-family: PingFang SC;
        line-height: 28px;
        color: #333333;
    }

    .section_8 {
        position: relative;
        padding: 100px 120px 0;
        background-color: #101a29;

        .image_12 {
            width: 308px;
            height: 56px;
        }

        .group_15 {
            margin-top: 46px;

            .group_16 {
                width: 757px;

                .font_8 {
                    font-size: 32px;
                    font-family: PingFang SC;
                    line-height: 30px;
                    color: #ffffff;
                }

                .text_20 {
                    line-height: 30px;
                }

                .text_21 {
                    line-height: 30px;
                }

                .text_22 {
                    color: #ffffff;
                    line-height: 26px;
                    text-align: justify;
                }
            }

            .group_17 {
                margin-bottom: 4px;
                width: 140px;

                .image_13 {
                    border-radius: 8px;
                    width: 120px;
                    height: 120px;
                }

                .text_23 {
                    margin-left: 4px;
                    color: #ffffff;
                    line-height: 15px;
                }
            }
        }

        .group_18 {
            margin-top: 78px;
            padding: 46px 0 22px;
            border-top: solid 1px #212a38;
            align-items: center;

            .text_24 {
                flex: 1;
                line-height: 13px;
                text-align: center;
            }

            .text_25 {
                flex: 1;
                text-align: center;
                line-height: 13px;
            }

            .text_26 {
                flex: 1;
                text-align: center;
                line-height: 13px;
            }

            .text_27 {
                line-height: 14.5px;
            }

            .image_14 {
                width: 16px;
                height: 16px;
            }

            .footerLink {
                display: flex;
                justify-content: space-between;
                width: 20%;
                margin: 0 auto;
                text-align: center;

                a {
                    text-decoration: none;
                }

                span {
                    font-family: 'PingFang SC-Regular';
                    font-size: 14px;
                    color: #FFFFFF;
                }
            }
        }
    }

    .font_6 {
        font-size: 18px;
        font-family: PingFang SC;
    }

    .font_7 {
        font-size: 16px;
        font-family: PingFang SC;
        line-height: 15px;
    }

    .font_4 {
        font-size: 14px;
        font-family: PingFang SC;
        line-height: 13px;
        color: #ffffff;
    }
}

/************************************************************
** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
** 否则页面将无法正常显示                                  **
************************************************************/

#app {
    width: 100vw;
    height: 100vh;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-evenly {
    justify-content: space-evenly;
}

.items-start {
    align-items: flex-start;
}

.items-end {
    align-items: flex-end;
}

.items-center {
    align-items: center;
}

.items-baseline {
    align-items: baseline;
}

.items-stretch {
    align-items: stretch;
}

.self-start {
    align-self: flex-start;
}

.self-end {
    align-self: flex-end;
}

.self-center {
    align-self: center;
}

.self-baseline {
    align-self: baseline;
}

.self-stretch {
    align-self: stretch;
}

.flex-1 {
    flex: 1 1 0%;
}

.flex-auto {
    flex: 1 1 auto;
}

.grow {
    flex-grow: 1;
}

.grow-0 {
    flex-grow: 0;
}

.shrink {
    flex-shrink: 1;
}

.shrink-0 {
    flex-shrink: 0;
}

.relative {
    position: relative;
}

.ml-2 {
    margin-left: 2px;
}

.mt-2 {
    margin-top: 2px;
}

.ml-4 {
    margin-left: 4px;
}

.mt-4 {
    margin-top: 4px;
}

.ml-6 {
    margin-left: 6px;
}

.mt-6 {
    margin-top: 6px;
}

.ml-8 {
    margin-left: 8px;
}

.mt-8 {
    margin-top: 8px;
}

.ml-10 {
    margin-left: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.ml-12 {
    margin-left: 12px;
}

.mt-12 {
    margin-top: 12px;
}

.ml-14 {
    margin-left: 14px;
}

.mt-14 {
    margin-top: 14px;
}

.ml-16 {
    margin-left: 16px;
}

.mt-16 {
    margin-top: 16px;
}

.ml-18 {
    margin-left: 18px;
}

.mt-18 {
    margin-top: 18px;
}

.ml-20 {
    margin-left: 20px;
}

.mt-20 {
    margin-top: 20px;
}

.ml-22 {
    margin-left: 22px;
}

.mt-22 {
    margin-top: 22px;
}

.ml-24 {
    margin-left: 24px;
}

.mt-24 {
    margin-top: 24px;
}

.ml-26 {
    margin-left: 26px;
}

.mt-26 {
    margin-top: 26px;
}

.ml-28 {
    margin-left: 28px;
}

.mt-28 {
    margin-top: 28px;
}

.ml-30 {
    margin-left: 30px;
}

.mt-30 {
    margin-top: 30px;
}

.ml-32 {
    margin-left: 32px;
}

.mt-32 {
    margin-top: 32px;
}

.ml-34 {
    margin-left: 34px;
}

.mt-34 {
    margin-top: 34px;
}

.ml-36 {
    margin-left: 36px;
}

.mt-36 {
    margin-top: 36px;
}

.ml-38 {
    margin-left: 38px;
}

.mt-38 {
    margin-top: 38px;
}

.ml-40 {
    margin-left: 40px;
}

.mt-40 {
    margin-top: 40px;
}

.ml-42 {
    margin-left: 42px;
}

.mt-42 {
    margin-top: 42px;
}

.ml-44 {
    margin-left: 44px;
}

.mt-44 {
    margin-top: 44px;
}

.ml-46 {
    margin-left: 46px;
}

.mt-46 {
    margin-top: 46px;
}

.ml-48 {
    margin-left: 48px;
}

.mt-48 {
    margin-top: 48px;
}

.ml-50 {
    margin-left: 50px;
}

.mt-50 {
    margin-top: 50px;
}

.ml-52 {
    margin-left: 52px;
}

.mt-52 {
    margin-top: 52px;
}

.ml-54 {
    margin-left: 54px;
}

.mt-54 {
    margin-top: 54px;
}

.ml-56 {
    margin-left: 56px;
}

.mt-56 {
    margin-top: 56px;
}

.ml-58 {
    margin-left: 58px;
}

.mt-58 {
    margin-top: 58px;
}

.ml-60 {
    margin-left: 60px;
}

.mt-60 {
    margin-top: 60px;
}

.ml-62 {
    margin-left: 62px;
}

.mt-62 {
    margin-top: 62px;
}

.ml-64 {
    margin-left: 64px;
}

.mt-64 {
    margin-top: 64px;
}

.ml-66 {
    margin-left: 66px;
}

.mt-66 {
    margin-top: 66px;
}

.ml-68 {
    margin-left: 68px;
}

.mt-68 {
    margin-top: 68px;
}

.ml-70 {
    margin-left: 70px;
}

.mt-70 {
    margin-top: 70px;
}

.ml-72 {
    margin-left: 72px;
}

.mt-72 {
    margin-top: 72px;
}

.ml-74 {
    margin-left: 74px;
}

.mt-74 {
    margin-top: 74px;
}

.ml-76 {
    margin-left: 76px;
}

.mt-76 {
    margin-top: 76px;
}

.ml-78 {
    margin-left: 78px;
}

.mt-78 {
    margin-top: 78px;
}

.ml-80 {
    margin-left: 80px;
}

.mt-80 {
    margin-top: 80px;
}

.ml-82 {
    margin-left: 82px;
}

.mt-82 {
    margin-top: 82px;
}

.ml-84 {
    margin-left: 84px;
}

.mt-84 {
    margin-top: 84px;
}

.ml-86 {
    margin-left: 86px;
}

.mt-86 {
    margin-top: 86px;
}

.ml-88 {
    margin-left: 88px;
}

.mt-88 {
    margin-top: 88px;
}

.ml-90 {
    margin-left: 90px;
}

.mt-90 {
    margin-top: 90px;
}

.ml-92 {
    margin-left: 92px;
}

.mt-92 {
    margin-top: 92px;
}

.ml-94 {
    margin-left: 94px;
}

.mt-94 {
    margin-top: 94px;
}

.ml-96 {
    margin-left: 96px;
}

.mt-96 {
    margin-top: 96px;
}

.ml-98 {
    margin-left: 98px;
}

.mt-98 {
    margin-top: 98px;
}

.ml-100 {
    margin-left: 100px;
}

.mt-100 {
    margin-top: 100px;
}
</style>