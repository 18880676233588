<template>
    <div class="box">
        <div class="scroll_box" @scroll="scrollChange">
            <ul>
                <li v-for="(item, index) in arr" :key="item">{{ item }}</li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { reactive, nextTick, onMounted } from 'vue';

let arr = reactive(["广告开户", "账户管理", "素材模板", "效果优化", "跨境情报", "知识培训"])
let liH = 40
let index = 0
let showNum = 5
let height = Math.floor(showNum / 2) * liH

const emit = defineEmits(['change'])

onMounted(() => {
    document.querySelector('ul').style.marginTop = `${height}px`
    document.querySelector('ul').style.marginBottom = `${height}px`
    chooseLi(index)
})

const scrollChange = e => {
    // 阻止滚动事件的默认行为
    e.preventDefault()
}

// const scrollChange = (e) => {
//     let scrollTop = e.target.scrollTop
//     index = Math.floor(scrollTop / liH)
//     if (scrollTop % liH !== 0) {
//         setTimeout(() => {
//             setActive(index)
//         }, 500);
//     }
// }

const chooseLi = (index) => {
    setActive(index)
    emit('change', index)
}

const setActive = (index) => {
    nextTick(() => {
        document.querySelector('.scroll_box').scrollTo({ top: index * liH, behavior: 'smooth' })
        let lis = document.querySelectorAll('li')
        for (let j = 1; j <= index; j++) {
            lis[index - j].style.fontSize = `${20 - j * 2}px`
            lis[index - j].style.opacity = ".6"
            lis[index - j].style.color = "#8C8C8C"
        }
        for (let j = 1; j < lis.length - index; j++) {
            lis[index + j].style.fontSize = `${20 - j * 2}px`
            lis[index + j].style.opacity = ".6"
            lis[index + j].style.color = "#8C8C8C"
        }
        if (index !== 0) {
            lis[index - 1].style.color = "#8C8C8C"
            lis[index - 1].style.opacity = "1"
        }
        if (index !== lis.length - 1) {
            lis[index + 1].style.color = "#8C8C8C";
            lis[index + 1].style.opacity = "1"
        }
        lis[index].style.color = "#2B82FF"
        lis[index].style.fontSize = "20px"
        lis[index].style.opacity = "1"
    })
}

defineExpose({
    chooseLi
})
</script>

<style lang="less" scoped>
.box {
    width: 80px;
    height: 200px;
    position: absolute;
    left: 18%;
    top: 250px;
}

@media screen and (max-width: 1650px) {
    .box {
        left: 13%;
    }
}

.scroll_box {
    width: 80px;
    height: 200px;
    text-align: center;
    margin: 100px auto;
    overflow: hidden;
    scrollbar-width: none;
}

.scroll_box::-webkit-scrollbar {
    display: none;
}

ul,
li {
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    color: #2B82FF;
    font-family: 'PingFang SC-Medium';
    line-height: 40px;
    // cursor: pointer;
}

li {
    height: 40px;
}
</style>